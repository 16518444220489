import React from 'react';
import Style from './About.module.scss';
import Terminal from "./Terminal";
import { Box } from "@mui/material";
import { info } from "../../info/Info";


export default function About({ innerRef }) {
    const firstName = info.firstName.toLowerCase()

    const aboutMe = [
        { color: info.baseColor, command: `${firstName}${info.lastName.toLowerCase()}`, text: `cat about${firstName}` },
        { color: info.baseColor, command: `about${firstName}`, text: `${info.bio}`, suffixClass: Style.green }
    ]

    const skills = [
        { color: info.baseColor, command: `${firstName}${info.lastName.toLowerCase()}`, text: "cd skills/tools" },
        { color: info.baseColor, command: "skills/tools", text: "ls", suffixClass: Style.green },
        { color: info.baseColor, command: "Proficient With", text: info.skills.proficientWith.map((proficiency, index) => <li key={index}>{proficiency}</li>), suffixClass: Style.skills },
        { color: info.baseColor, command: "Exposed To", text: info.skills.exposedTo.map((skill, index) => <li key={index}>{skill}</li>), suffixClass: Style.skills },
    ]


    // function miscText() {
    //     return <>
    //         <p><span style={{ color: info.baseColor }}>{firstName}{info.lastName.toLowerCase()} $</span> cd
    //             hobbies/interests</p>
    //         <p><span style={{ color: info.baseColor }}>hobbies/interests <span
    //             className={Style.green}>(main)</span> $</span> ls</p>
    //         <ul>
    //             {info.hobbies.map((hobby, index) => (
    //                 <li key={index}><Box component={'span'} mr={'1rem'}>{hobby.emoji}</Box>{hobby.label}</li>
    //             ))}
    //         </ul>
    //     </>;
    // }

    return (
        <Box ref={innerRef} display={'flex'} flexDirection={'column'} alignItems={'center'} id={'about'}>
            <Terminal textArray={aboutMe} />
            <Terminal textArray={skills} />
            {/* <Terminal text={miscText()} /> */}
        </Box>
    )
}