import { Box, Grid, Pagination, Typography } from "@mui/material";
import { useState } from "react";
import "./style.css";
import { mock } from "./constants";
import { formatDate } from "../../utils";
import { NavLink } from "react-router-dom";

const Blogs = () => {

  const itemsPerPage = 5; // Adjust number of items per page as needed
  const [page, setPage] = useState(1);

  const handleChangePage = (event, value) => {
    setPage(value);
  };

  const startIndex = (page - 1) * itemsPerPage;
  const paginatedData = mock.slice(startIndex, startIndex + itemsPerPage);

  return (
    <Box className="highlights">
      <Typography
        variant="h5"
        sx={{
          textAlign: "left",
          fontStyle: " italic",
          fontWeight: "700",
          pb: 4,
        }}
      >
        Blogs (DUMMY)
      </Typography>
      <br />
      <Grid container spacing={2}>
        {paginatedData.map((item) => {
          const { key, title, image, url = "", date, description } =
            item;
          return (
              <Box key={key} className="highlight-card">
                <img src={image} alt={title} className="highlight-feed-image" />
                <Box pl={2} className="highlight-content">
                  <Typography
                    variant="body2"
                    // className="createdAt"
                    color="#FFFFFF"
                    sx={{ pb: 1 }}
                  >
                    {formatDate(date)}
                  </Typography>
                  <div>
                    <Typography
                      style={{ textDecoration: "none" }}
                      as={NavLink}
                      to={url}
                      target="_blank"
                      variant="subtitle1"
                      className="heading"
                    >
                      {title}
                    </Typography>
                  </div>
                  <Typography variant="body2" color="#FFFFFF" sx={{ pt: 2 }}>
                    {description}
                  </Typography>
                </Box>
              </Box>
          );
        })}
      </Grid>
      <Box
        mt={2}
        sx={{ display: "flex", justifyContent: "center" }}
        className="pagination-nav sx-pagination"
      >
        <Pagination
          className="paginations"
          count={Math.ceil(mock.length / itemsPerPage)}
          page={page}
          onChange={handleChangePage}
          color="primary"
        />
      </Box>
    </Box>
  );
};

export default Blogs;
