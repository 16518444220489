export const mock = [
    {
      "key": 1,
      "title": "Starter Kit for Generative AI",
      "description": "A comprehensive guide to get started with generative AI, covering the basics and advanced techniques.",
      "image": "https://eu-images.contentstack.com/v3/assets/blt6b0f74e5591baa03/bltfd36e68ac7a0f3b2/651b29bb3671b45abcc7e4c8/Generative_AI_(2).png?disable=upscale&width=1200&height=630&fit=crop",
      "date": "2024-08-01"
    },
    {
      "key": 2,
      "title": "Get Started with Python Flask",
      "description": "Learn how to create robust web applications using Python Flask with this beginner's guide.",
      "image": "https://www.python.org/static/community_logos/python-powered-w-200x80.png",
      "date": "2024-08-01"
    },
    {
      "key": 3,
      "title": "MERN Stack Development",
      "description": "A practical introduction to building full-stack web applications using MongoDB, Express, React, and Node.js.",
      "image": "https://www.topsinfosolutions.com/wp-content/uploads/2020/07/mern-stack.png",
      "date": "2024-08-01"
    },
    {
      "key": 4,
      "title": "Azure Cloud for Developers",
      "description": "An essential guide for developers looking to leverage the power of Azure Cloud for scalable applications.",
      "image": "https://azure.microsoft.com/svghandler/cloud-journey/",
      "date": "2024-08-01"
    },
    {
      "key": 5,
      "title": "Building AI Models with Python",
      "description": "Explore the fundamentals of building AI models using Python, from data preprocessing to model deployment.",
      "image": "https://www.python.org/static/img/python-logo.png",
      "date": "2024-08-01"
    },
    {
      "key": 6,
      "title": "Creating APIs with Strapi",
      "description": "Learn how to create powerful and flexible APIs using Strapi, the open-source headless CMS.",
      "image": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQHzhoJD1MYL-fjF1eRwZehUKpz9Jjx6rMsxA&s",
      "date": "2024-08-01"
    },
    {
      "key": 7,
      "title": "Introduction to Machine Learning",
      "description": "A beginner's guide to understanding and implementing machine learning algorithms.",
      "image": "https://emeritus.org/in/wp-content/uploads/sites/3/2023/03/types-of-machine-learning.jpg.optimal.jpg",
      "date": "2024-08-01"
    },
    {
      "key": 8,
      "title": "CNNs for Image Classification",
      "description": "Dive into Convolutional Neural Networks (CNNs) and their applications in image classification tasks.",
      "image": "https://analyticsindiamag.com/wp-content/uploads/2018/01/nural-network-05.jpg",
      "date": "2024-08-01"
    },
    {
      "key": 9,
      "title": "RNNs for Sequence Data",
      "description": "Learn about Recurrent Neural Networks (RNNs) and how they are used to process sequence data.",
      "image": "https://media.assettype.com/analyticsinsight/import/wp-content/uploads/2023/10/Recurrent-Neural-Networks-RNNs-for-Sequential-Data-Analysis.jpg?w=1200&h=675&auto=format%2Ccompress&fit=max&enlarge=true",
      "date": "2024-08-01"
    },
    {
      "key": 10,
      "title": "AI in Natural Language Processing",
      "description": "Explore how AI techniques are revolutionizing natural language processing and understanding.",
      "image": "https://syndelltech.com/wp-content/uploads/2023/03/Artificial-intelligence-and-natural-language-processing.png",
      "date": "2024-08-01"
    }
  ]
  